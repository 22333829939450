import { useMemo, useState } from 'react'

import { useTranslation } from '@/lib/i18n'
import { UseAutocompleteProps } from '@mui/base/useAutocomplete/useAutocomplete'
import { Box, InputAdornment, TextField, Typography, useAutocomplete } from '@mui/material'

import { CustomizedAutocompleteItem } from './customized-autocomplete-item'
import { ListBox } from './list-box'
import { ListWrapper } from './list-wrapper'
import { AutocompleteItem } from './types'
import { useGroupedAndSortedOptions } from './use-grouped-and-sorted-options/use-grouped-and-sorted-options'

import { ClearButton } from '../clear-button'
import { SearchIcon } from '../icons'

import { DEFAULT_SEARCH_TERM_LENGTH, DataTestIds } from '../../const'

export type CustomizedAutocompleteProps = {
    loading: boolean
    onChange: (value: string) => void
    onSelectOption: UseAutocompleteProps<AutocompleteItem, false, false, true>['onChange']
    options: Array<AutocompleteItem>
    searchTerm?: string
    width: number
    clearOnSelect?: boolean
}

export const CustomizedAutocomplete: React.FC<CustomizedAutocompleteProps> = ({
    loading,
    onChange,
    onSelectOption,
    options,
    searchTerm,
    width,
    clearOnSelect = false,
}) => {
    const { t } = useTranslation('common')

    const [value, setValue] = useState<AutocompleteItem | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const groupedAndSortedOptions = useGroupedAndSortedOptions(options)
    const flatOptions = useMemo(() => groupedAndSortedOptions.flatMap((group) => group.options), [groupedAndSortedOptions])

    const { focused, inputValue, getClearProps, getInputProps, getListboxProps, getOptionProps, getRootProps, setAnchorEl } =
        useAutocomplete({
            value,
            open: isOpen,
            clearOnBlur: clearOnSelect,
            blurOnSelect: true,
            onOpen: () => setIsOpen(true),
            onClose: () => setIsOpen(false),
            onChange: (event, newValue, reason) => {
                setValue(newValue)
                // Close the dropdown after selecting an option
                setIsOpen(false)

                if (onSelectOption) {
                    onSelectOption(event, newValue, reason)
                }

                if (clearOnSelect) {
                    setValue(null)
                }
            },
            onInputChange: (_event, newValue, reason) => {
                if (reason === 'input') {
                    onChange(newValue)
                }

                if (reason === 'clear' || reason === 'reset' || reason === 'blur') {
                    onChange('')
                    setValue(null)
                }
            },
            getOptionKey: (option) => option.id,
            getOptionLabel: (option) => option.label,
            isOptionEqualToValue: (option, value) => option?.id === value?.id,
            options: flatOptions,
            // This is needed so that MUI does not do any internal filtering.
            // We already ensure to display the correct options.
            filterOptions: () => flatOptions,
        })

    const { color, size, ...inputProps } = getInputProps()
    const inputValueTooShort = (inputValue || '').trim().length < DEFAULT_SEARCH_TERM_LENGTH

    const clearProps = getClearProps()

    return (
        <div {...getRootProps()}>
            <Box width={width}>
                <TextField
                    ref={setAnchorEl}
                    className={focused ? 'focused' : ''}
                    data-testid={DataTestIds.globalSearch.input}
                    size="small"
                    color="secondary"
                    placeholder={t('searchTermLength')}
                    sx={{
                        height: 40,
                        '& *> input': {
                            fontSize: 16,
                        },
                    }}
                    slotProps={{
                        input: {
                            inputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ marginLeft: -0.5 }}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: inputValue && (
                                <InputAdornment position="end" sx={{ marginRight: -0.75 }}>
                                    <ClearButton {...clearProps} loading={loading} />
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Box>
            {focused && !inputValueTooShort && searchTerm && !loading && options.length === 0 ? (
                <ListBox width={width}>
                    <ListWrapper {...getListboxProps()}>
                        <li>
                            <Box padding={2}>
                                <Typography variant="body2" color="dark.coloured">
                                    {t('noSearchResults')}
                                </Typography>
                            </Box>
                        </li>
                    </ListWrapper>
                </ListBox>
            ) : null}
            {options.length > 0 && isOpen ? (
                <ListBox width={width}>
                    <ListWrapper {...getListboxProps()}>
                        <CustomizedAutocompleteItem
                            groupedAndSortedOptions={groupedAndSortedOptions}
                            getOptionProps={getOptionProps}
                            searchTerm={searchTerm}
                        />
                    </ListWrapper>
                </ListBox>
            ) : null}
        </div>
    )
}
